.PartnersSection {
  background: white;
  .logos-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .partner-logo {
      margin: 16px 32px;
      height: 196px;
      width: 256px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      @media all and (max-width: 1200px) {
        margin: 8px auto;
        height: 96px;
        width: 128px;
      }
    }
  }
}
