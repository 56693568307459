.d-inline-flex,
.d-flex {
  display: flex;
  flex-direction: row;
  &.center-x {
    justify-content: center;
  }
  &.right-x {
    justify-content: flex-end;
  }
  &.center-y {
    align-items: center;
  }
  &.stretch {
    justify-content: stretch;
  }
}

.d-flex-col {
  display: flex;
  flex-direction: column;
  &.center-x {
    align-items: center;
  }
  &.right-x {
    align-items: flex-end;
  }
  &.center-y {
    justify-content: center;
  }
}

.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-inline {
  display: inline;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

@media all and (max-width: 900px) {
  .d-xs-none {
    display: none;
  }
}
