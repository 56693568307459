.about-page {
  .LargeMidTextSectionWithBg {
    font-weight: bold;
    text-align: center;
    background: var(--color-background-gray);
    line-height: calc(60 / 50);
    p {
      font-size: 2.4rem;
      font-weight: 300;
      line-height: calc(40 / 24);
      @media all and (max-width: 1600px) {
        font-size: 2rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }
    }
    .h1 {
      font-size: 4.8rem;

      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2.8rem;
      }
    }

    .section-bg {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .secondary {
      color: var(--color-secondary);
    }
    .primary {
      color: var(--color-primary);
    }
  }
}
