:root {
  --color-primary: #45dfa8;
  --color-primary-dark: #23cd91;
  --color-secondary: #1285ff;
  --color-secondary-light: hsl(211, 100%, 94%);
  --color-secondary-dark: #006ce0;
  --color-background: #ffffff;
  // --color-background-gray: #fbfbfb;
  --color-background-gray: #f7f7f7;
  --color-fonts-gray: #b5b5b5;
  --color-border-gray: #e7e7e7;
  --color-fonts: #000000;
  --color-fonts-on-primary: #ffffff;
  --color-fonts-on-secondary: #ffffff;
}

.primary{
  color: var(--color-primary);
}

.secondary{
  color: var(--color-secondary);
}