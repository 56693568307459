*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
  font-family: "Rustica", sans-serif;
}

html {
  font-size: 10px;
  font-family: "Rustica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // filter: invert(0.95);
  scroll-behavior: smooth;
  vertical-align: middle;
  @media (max-width: 1900px) {
    font-size: 9px;
  }
  @media (max-width: 1700px) {
    // font-size: 8.5px;
  }
}

body {
  background: var(--color-background-gray);
  color: var(--color-fonts);
}

a {
  text-decoration: none;
  color: var(--color-fonts);
}

*:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 2.4rem !important;
  font-weight: 300 !important;
}

.hideOnMobile {
  @media all and (max-width: 900px) {
    display: none;
  }
}
.showOnMobile {
  @media all and (min-width: 900px) {
    display: none;
  }
}
