.menu-btn-wrapper {
  cursor: pointer;
  padding: 4px;
  .menu-btn {
    display: flex;
    align-items: center;
    pointer-events: all;
    width: 22px;
    height: 16px;
    line-height: 16px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    span {
      border-radius: 2px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      display: block;
      position: absolute;
      height: 0px;
      width: 100%;
      border: 1.2px solid var(--color-fonts);
      background: var(--color-fonts);
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 7px;
    }

    span:nth-child(4) {
      top: 14px;
    }

    &.open span:nth-child(1) {
      top: 6px;
      width: 0%;
      left: 50%;
    }

    &.open span:nth-child(2) {
      transform: rotate(45deg);
    }

    &.open span:nth-child(3) {
      transform: rotate(-45deg);
    }

    &.open span:nth-child(4) {
      top: 7px;
      width: 0%;
      left: 50%;
    }
  }
}
