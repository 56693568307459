.article-page {
  .ContentSection {
    background: var(--color-background-gray);
    img {
      margin-top: 32px;
      margin-bottom: 32px;
      width: 100%;
    }
    table {
      font-size: 2.1rem;
      padding-top: 16px;
      padding-bottom: 16px;
      font-weight: 300;

      td {
        padding: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    pre {
      font-size: 1.6rem;
      padding-top: 16px;
      padding-bottom: 16px;
      font-weight: 300;
      background: #f7f7f7;
      padding: 32px;
      border-radius: 8px;
      box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.1);
      line-height: 1.4;
      letter-spacing: 0.8px;
    }
    ol,
    ul {
      font-size: 2.1rem;
      padding-top: 16px;
      padding-bottom: 16px;
      font-weight: 300;

      li {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
    p {
      font-size: 2.1rem;
      font-weight: 300;
      line-height: calc(45 / 21);
      padding-bottom: 8px;
      padding-top: 8px;
      @media all and (max-width: 1600px) {
        font-size: 1.8rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }
    }

    h1 {
      // display: none;
      font-size: 4rem;
      line-height: calc(60 / 50);
      font-weight: bold;
    }

    h2 {
      font-size: 4rem;
      line-height: calc(60 / 50);
      font-weight: bold;

      @media all and (max-width: 1600px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 2.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2rem;
      }
    }

    blockquote p,
    h3 {
      font-size: 3.4rem;
      line-height: calc(55 / 35);
      font-weight: 300;
      @media all and (max-width: 1600px) {
        font-size: 2.4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 2rem;
      }
    }

    h2,
    h3 {
      font-weight: 300;
      padding-top: 64px;
      padding-bottom: 24px;
    }

    h4 {
      font-size: 3rem;
      line-height: calc(55 / 35);
      font-weight: bold;
      @media all and (max-width: 1600px) {
        font-size: 2rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }
      padding-top: 32px;
      padding-bottom: 16px;
    }

    figure {
      img {
        margin-bottom: 12px;
      }
      figcaption {
        font-size: 1.4rem;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 300;
        font-style: italic;
      }
    }

    blockquote {
      font-weight: 300;
      padding-bottom: 64px;
      padding-top: 64px;
      cite {
        padding-left: 64px;
        font-size: 1.4rem;
        color: rgba(0, 0, 0, 0.5);
      }
      p {
        position: relative;
        padding-bottom: 0px;
        padding-left: 64px;
        font-weight: 300;
        &::before {
          height: calc(100% - 16px);
          content: "";
          width: 2px;
          display: block;
          position: absolute;
          left: 0px;
          background: var(--color-secondary);
        }
      }
    }

    .secondary {
      color: var(--color-secondary);
    }
    .primary {
      color: var(--color-primary);
    }
  }
}
