.Form {

  .form-info{
    font-size: 1.2rem;
    line-height: 1.25;
    font-weight: 300;
    letter-spacing: -0.3px;
    a{
      color: inherit;
      font-weight: 600;
    }
  }
}
