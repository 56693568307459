div[class*="url--/pl/zasoby/tag/"],
div[class*="url--/en/resources/tag/"],
.blog-page {
  .BlogListSection {
    background: var(--color-background-gray);
    min-height: 75vh;

    .h1 {
      font-size: 4.8rem;
      font-weight: bold;
      line-height: calc(60 / 50);

      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2.8rem;
      }
    }

    .secondary {
      color: var(--color-secondary);
    }
    .primary {
      color: var(--color-primary);
    }
  }
}
