.IrmatiqUsage {

  &.IrmatiqUsers {
    background: var(--color-primary);
  }
  .color-white {
    color: white !important;
  }
  .section-intro {
    h2 {
      font-size: 4.8rem;
      text-align: center;
      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2.8rem;
      }
    }
    .paragraph,
    p {
      text-align: center;
      font-size: 1.8rem;
      line-height: 1.5;
      
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }
  }
  &.IrmatiqBenefits {
    h3 {
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.5;
      padding-bottom: 8px;
      text-decoration: underline;
      @media all and (max-width: 1900px) {
        font-size: 2rem;
      }
      @media all and (max-width: 1600px) {
        font-size: 1.8rem;
      }
      @media all and (max-width: 900px) {
        font-size: 1.6rem;
        line-height: 1.25;
      }
    }
  }
}

.bg-circles-gradient{
  background: var(--color-background-gray);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
