.VisualizationsSection {
  background: #242424; //hsl(159, 41%, 6%); // #222;

  h2 {
    font-size: 5.8rem;
    text-align: center;
    color: white;
    padding-bottom: 48px;
    line-height: calc(60 / 50);
    background: -webkit-linear-gradient(60deg, #1285ff, #23cd91);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // text-shadow: 0px 0px 96px rgba(255, 255, 255, 0.5);
    text-shadow: 0px 0px 96px rgba(255, 255, 255, 0.15);
    filter: contrast(2);


    @media all and (max-width: 1600px) {
      font-size: 4rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 3.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.8rem;
    }
  }

  h3 {
    font-size: 3.2rem;
    line-height: calc(60 / 50);
    color: white;
    padding-bottom: 12px;
    background: -webkit-linear-gradient(60deg, #1285ff, #23cd91);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px 96px rgba(255, 255, 255, 0.15);
    filter: contrast(2);

    @media all and (max-width: 1600px) {
      font-size: 2.8rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 2.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 2.4rem;
    line-height: calc(60 / 50);
    color: white;
    padding-bottom: 12px;
    @media all and (max-width: 1600px) {
      font-size: 2.2rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 2rem;
    }
    @media all and (max-width: 900px) {
      font-size: 1.8rem;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: calc(33px / 18px);
    font-weight: 300;
    color: white;

    @media all and (max-width: 1600px) {
      font-size: 1.6rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.4rem;
    }
  }

  img {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 24px 128px 32px rgb(18, 133, 255, 0.25); //rgba(255, 255, 255, 0.1);
    border: 2px solid #23cd91;
    @media all and (max-width: 1600px) {
      border-radius: 16px;
    }
    @media all and (max-width: 900px) {
      border-radius: 8px;
    }
  }
}
