.CookieConsent {
  align-items: center !important;
  u {
    color: var(--color-primary) !important;
    &:hover {
      color: var(--color-secondary) !important;
    }
  }
  a {
    font-weight: 600;
  }
}
