.ContactSection {
  color: white;
  background: var(--color-primary);
  h2 {
    font-size: 4.8rem;
    font-weight: 500;
    @media all and (max-width: 1600px) {
      font-size: 4rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 3.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.8rem;
    }
  }

  .p-status {
    font-size: 1.4rem;
    position: absolute;
    margin-top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
  .form-child {
    width: 100%;
    input {
      color: var(--color-fonts-on-primary);
      border-color: var(--color-fonts-on-primary);
    }
    &.isFocus {
      input {
        border-color: var(--color-fonts);
        color: var(--color-fonts);
      }
    }
    a {
      color: var(--color-fonts-on-primary);
    }
  }
  position: relative;

  .button {
    &:hover {
      color: black;
      background: white; //black;
    }
  }
}

#contactFormSection {
  width: 0px;
  height: 96px;
  position: absolute;
  top: -96px;
  @media all and (max-width: 900px) {
    height: 70px;
    top: -70px;
  }
}
