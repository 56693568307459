.PricingSection {
  background: var(--color-background-gray);

  @media all and (max-width: 1200px) {
  }

  .align-center-col {
    align-items: center;
    display: flex;
    @media all and (max-width: 1200px) {
      justify-content: center;
    }
  }

  h2 {
    font-size: 4.8rem;
    text-align: center;
    @media all and (max-width: 1600px) {
      font-size: 4rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 3.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.8rem;
    }
  }

  h3 {
    font-size: 3.4rem;
    line-height: calc(60 / 50);

    @media all and (max-width: 1200px) {
      font-size: 2rem;
    }
    @media all and (max-width: 900px) {
      font-size: 1.6rem;
    }
  }

  p {
    font-size: 2rem;
  }

  .Tile {
    justify-content: center;
    text-align: center;
    // background: linear-gradient(180deg, #1285ff 0%, #45dfa8 100%);
    // color: var(--color-background);

    .button {
      margin: auto;
    }
  }

  .pricing-label {
    font-size: 1.4.8rem;
    font-weight: bold;
  }

  .pricing-price {
    display: flex;
    text-align: center;

    img {
      margin: 0px auto;
      width: 92%;
      max-width: 260px;
      @media all and (max-width: 1200px) {
        max-width: 240px;
      }
      @media all and (max-width: 600px) {
        max-width: 220px;
      }
    }

    // .cell {
    //   padding: 32px 32px 30px 32px;
    //   display: flex;
    //   color: var(--color-secondary);
    //   align-items: flex-end;
    //   margin: auto;
    //   font-size: 4.4rem;
    //   font-weight: 300;
    //   border: 1px solid var(--color-fonts-gray);
    //   border-radius: 64px;

    //   span {
    //     color: var(--color-fonts);
    //     font-size: 1.4rem;
    //     font-weight: 600;
    //     line-height: 2.2;
    //   }
    // }
  }
  .options-wrapper {
    display: inline-flex;
    // margin: auto;
    padding: 32px;
    // border: 1px solid var(--color-fonts-gray);
    border-radius: 16px;
    color: var(--color-fonts);
    background: var(--color-background);
    ul {
      font-size: 2rem;
      text-align: left;
      margin: auto;
      padding-inline-start: 16px;
      li {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
  background: var(--color-background);

  .Tile {
    transition-duration: 0.2s;
    z-index: 222;
    position: relative;
    background: var(--color-background);
    border: 1px solid var(--color-border-gray);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
      background: var(--color-background);
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 2;
      transition-duration: 0.3s;
      border-radius: 8px;
    }
    &::before {
      background: linear-gradient(180deg, #1285ff 0%, #45dfa8 100%);
      top: 3px;
      z-index: 1;
    }
    * {
      z-index: 3;
      position: relative;
    }
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);

      &::before {
        background: linear-gradient(180deg, #1285ff 0%, #45dfa8 100%);
        top: 12px;
        left: 8px;
        z-index: 1;
      }

      .options-wrapper {
        text-align: left;
      }
    }
  }
}
