.OpinionTile {
  display: block;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: var(--color-background);
  position: relative;
  user-select: none;
  text-align: center;

  .icon {
    height: 100px;
    width: 100px;
    margin: auto;
    border-radius: 128px;
    overflow: hidden;
    border: 1px solid #ddd;

    @media all and (max-width: 900px) {
      width: 96px;
      height: 96px;
    }
    @media all and (max-width: 600px) {
      height: 64px;
      width: 64px;
    }
  }
  h4 {
    font-size: 1.4rem;
    font-weight: 400;
    color: #666;
  }
  h3 {
    font-size: 2rem;
    font-weight: 600;
    line-height: calc(33 / 24);
    padding-bottom: 4px;
    padding-top: 8px;
    @media all and (max-width: 1600px) {
      font-size: 1.6rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.2rem;
    }
    @media all and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }
  p {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: calc(24 / 18);
    @media all and (max-width: 1600px) {
      font-size: 1.4.8rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 1.4rem;
    }
  }
}
