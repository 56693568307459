.button {
  display: block;
  font-weight: 500;
  font-size: 1.7rem;
  user-select: none;
  cursor: pointer;
  transition-duration: 0.15s;
  letter-spacing: 0.02em;
  box-shadow: 0px 4px 4px var(--color-boxshadow);
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  white-space: nowrap;

  @media all and (max-width: 1600px) {
    letter-spacing: 0.01em;
    font-size: 1.5rem;
  }

  @media all and (max-width: 1200px) {
    font-size: 1.4rem;
  }
  @media all and (max-width: 900px) {
    font-size: 1.2rem;
  }

  &.size-small {
    padding: 14px 24px 12px 24px;
  }

  &.size-regular {
    padding: 22px 46px 18px 46px;
    @media all and (max-width: 1600px) {
      padding: 16px 32px 13px 32px;
    }
    @media all and (max-width: 1200px) {
      padding: 14px 24px 12px 24px;
    }
  }

  &.size-large {
    padding: 24px 64px 20px 64px;
    @media all and (max-width: 1600px) {
      padding: 22px 48px 18px 48px;
    }
    @media all and (max-width: 1200px) {
      padding: 14px 24px 12px 24px;
    }
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0px 0px 0px var(--color-boxshadow);
  }

  &.button-fit-content {
    min-width: 0px !important;
    width: auto !important;
  }

  &.button-primary {
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-fonts-on-primary);

    &:hover {
      background: var(--color-secondary);
      border-color: var(--color-secondary);
    }
    &:active {
      background: var(--color-secondary-dark);
      border-color: var(--color-secondary-dark);
    }
  }

  &.button-secondary {
    background: var(--color-secondary-light);
    border-color: var(--color-secondary-light);
    color: var(--color-secondary-dark);
    cursor: default;
  }

  &.button-secondary-inactive {
    background: var(--color-secondary);
    border-color: var(--color-secondary);
    color: var(--color-fonts-on-secondary);

    &:hover {
      background: var(--color-primary);
      border-color: var(--color-primary);
    }
    &:active {
      background: var(--color-primary-dark);
      border-color: var(--color-primary-dark);
    }
  }

  &.button-arrow-icon {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--color-secondary);
    border-radius: 0px;
    padding-left: 0px;
    padding-right: 0px;
    color: var(--color-secondary);
    font-weight: 300;

    .icon {
      margin-left: 96px;
      transition-duration: 0.2s;
      @media all and (max-width: 1600px) {
        margin-left: 64px;
      }
      @media all and (max-width: 1200px) {
        margin-left: 32px;
      }
      @media all and (max-width: 900px) {
        width: 24px;
        margin-left: 24px;
      }
    }

    &:active {
      transform: translateY(0px);

      .icon {
        transform: translateX(8px);
      }
      // background: var(--color-secondary-dark);
      // border-color: var(--color-secondary-dark);
    }
  }
}
