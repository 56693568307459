.footer {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: calc(30 / 12);
  color: var(--color-fonts-gray);
  background: var(--color-background);
  
  @media all and (max-width: 900px) {
    text-align: center;
    .row {
      flex-direction: column-reverse !important;
    }
  }

  .info-nav {
    text-align: right;
    @media all and (max-width: 900px) {
      text-align: center;
      margin-bottom: 4px;
    }
  }

  a {
    color: var(--color-fonts-gray);
    margin-left: 64px;
    @media all and (max-width: 900px) {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
