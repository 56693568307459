.ImgInfoRow {
  @media all and (max-width: 900px) {
    flex-direction: column-reverse;
  }
  .content-col {
    margin-top: 32px;
    @media all and (max-width: 1200px) {
      margin-top: 16px;
      margin-bottom: 8px;
    }
    h3 {
      font-size: 3.4rem;
      line-height: calc(60 / 50);
      // @media all and (max-width: 1600px) {
      //   font-size: 4rem;
      // }
      // @media all and (max-width: 1200px) {
      //   font-size: 3.4rem;
      // }
      @media all and (max-width: 900px) {
        font-size: 2.8rem;
      }
      @media all and (max-width: 600px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.8rem;
      font-weight: 300;
      line-height: calc(33 / 18);
      max-width: 512px;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }
  }

  .img-col {
    @media all and (max-width: 900px) {
      // display: flex;
      margin-bottom: 16px;
    }
    .main-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @media all and (max-width: 900px) {
        transform: translateX(-5px) translateY(10px);
      }

      .main-img {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
      .main-img-shadow,
      .main-img {
        min-width: 512px;
        min-height: 512px;
        @media all and (max-width: 1600px) {
          min-width: 360px;
          min-height: 360px;
        }
        @media all and (max-width: 1200px) {
          min-width: 320px;
          min-height: 320px;
        }
        @media all and (max-width: 600px) {
          min-width: 280px;
          min-height: 280px;
        }
      }
    }
  }
  &.reversed {
    flex-direction: row-reverse;
    @media all and (max-width: 900px) {
      flex-direction: column-reverse;
    }
    .img-col {
      .main-img-wrapper {
        transform: translateX(22px) translateY(-22px);
        @media all and (max-width: 1200px) {
          transform: translateX(10px) translateY(-10px);
        }
        @media all and (max-width: 900px) {
          transform: translateX(-5px) translateY(10px);
        }
        .main-img-shadow {
          transform: translateX(-22px) translateY(22px);
          @media all and (max-width: 1200px) {
            transform: translateX(-10px) translateY(10px);
          }
          @media all and (max-width: 900px) {
            transform: translateX(10px) translateY(10px);
          }
        }
      }
    }
  }

  &.ImgInfoRow--no-shadow {
    .img-col {
      @media all and (max-width: 900px) {
        margin-bottom: 0px;
      }
      .main-img-wrapper {
        transform: translateX(0px) translateY(0px) !important;
      }
    }
  }

  &.ImgInfoRow--small {
    @media all and (min-width: 1599.99px) {
      padding-left: 96px;
      padding-right: 96px;
    }
    .img-col {
      @media all and (max-width: 900px) {
        display: flex;
        margin-bottom: 32px;
      }
      .main-img {
        min-width: 420px;
        min-height: 420px;
        @media all and (max-width: 1600px) {
          min-width: 320px;
          min-height: 320px;
        }
        @media all and (max-width: 1200px) {
          min-width: 280px;
          min-height: 280px;
        }
        @media all and (max-width: 900px) {
          min-width: 220px;
          min-height: 220px;
        }
      }
    }
  }
}
