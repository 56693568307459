.OpinionsSection {
  background: var(--color-background-gray);
  .swiper {
    padding: 0px 48px 48px 48px;
    @media all and (max-width: 900px) {
      padding: 0px 32px 32px 32px;
    }
    @media all and (max-width: 600px) {
      padding: 0px 0px 32px 0px;

      .swiper-button-next,
      .swiper-button-prev {
        top: calc(100% - 20px);
        transform: scale(0.85);
      }
    }
  }
  .section-intro {
    h2 {
      font-size: 4.8rem;
      text-align: center;
      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2.8rem;
      }
    }

    p {
      text-align: center;
      font-size: 1.8rem;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }
  }
}
