.ContactInfoSection {
  background: var(--color-background-gray);

  h2 {
    font-size: 4.8rem;
    line-height: calc(60 / 50);
    @media all and (max-width: 1600px) {
      font-size: 4rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 3.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.8rem;
    }
  }

  .content {
    p {
      font-size: 1.8rem;
      line-height: calc(33px / 18px);
      font-weight: 300;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }
  }
  .map-wrapper-col {
    .map-wrapper {
      position: relative;
      .map-shadow {
        background: linear-gradient(180deg, #1285ff 0%, #45dfa8 100%);
        z-index: 0;
        position: absolute;
        transform: translateX(-22px) translateY(22px);
        @media all and (max-width: 1200px) {
          transform: translateX(-10px) translateY(10px);
        }
      }
      #map {
        position: relative;
        z-index: 1;
        .place-card {
          display: none !important;
        }
      }
      .map-shadow,
      #map {
        min-width: 512px;
        min-height: 512px;
        border-radius: 22px;
        border: 0px;

        @media all and (max-width: 1600px) {
          min-width: 360px;
          min-height: 360px;
        }
        @media all and (max-width: 1200px) {
          min-width: 320px;
          min-height: 320px;
        }
        @media all and (max-width: 1200px) {
          margin-bottom: 32px;
        }
      }
    }
  }
}
