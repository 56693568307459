@use "./styles/_style.scss";

.page-wrapper {
  min-height: calc(100vh + 1px);
  max-width: 100%;
  overflow-x: hidden;
}

*:focus {
  // outline-offset: 5px;
  // outline: 3px solid black !important;
}
