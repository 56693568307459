.CheckBox {
  padding-bottom: 16px;
  padding-top: 4px;
  position: relative;

  .validation-messages {
    transition-duration: 0.15s;
    display: inline-block;
    transform: translateY(20px);
    color: var(--color-error);
    opacity: 0;

    &.active {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  label {
    font-size: 1.4rem;
    line-height: calc(20 / 14);
    font-weight: 300;
    user-select: none;
    display: flex;
    align-items: flex-start;
    @media all and (max-width: 1200px) {
      font-size: 1.2rem;
    }
    cursor: pointer;
    a {
      font-weight: 500;
      text-decoration: underline;
    }
  }
  input {
    margin-right: 8px;
    transform: translateY(4px);
    border: 0px;
    accent-color: var(--color-primary);
    box-shadow: inset 0px 0px 0px 10px white;
    cursor: pointer;
    &:checked {
      // box-shadow: inset 0px 0px 0px 10px var(--color-primary);
    }
    position: relative;

    &:checked:after {
      // transform: translateY(-4px) translateX(1px);
      // content: "\2713";
      // display: block;
      // color: var(--color-primary);
      // font-size: 1.4rem;
      // font-weight: 900;
      top: 5px;
      transform: rotate(60deg);
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      width: 9px;
      background: var(--color-primary);
      right: 4px;
    }

    &:checked:before {
      // transform: translateY(-4px) translateX(1px);
      // content: "\2713";
      // display: block;
      // color: var(--color-primary);
      // font-size: 1.4rem;
      // font-weight: 900;
      top: 5px;
      left: 3px;
      transform: rotate(-60deg);
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      width: 9px;
      background: var(--color-primary);
    }
  }
}
