div[class*="url--/pl/zasoby/tag/"],
div[class*="url--/en/resources/tag/"],
div[class="url--/resources/"],
.blog-page {
  .BlogHeroSection {
    font-weight: bold;
    background: var(--color-background-gray);
    line-height: calc(60 / 50);

    .reverse-on-mobile {
      @media all and (max-width: 900px) {
        flex-direction: column-reverse !important;
      }
    }
    p {
      font-size: 2.4rem;
      font-weight: 300;
      line-height: calc(40 / 24);
      @media all and (max-width: 1600px) {
        font-size: 2rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }
    }
    .h1 {
      font-size: 4.8rem;

      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2.8rem;
      }
    }

    .img-col {
      display: flex;
      @media all and (max-width: 900px) {
        margin-bottom: 48px;
      }
      img {
        width: 80%;
        margin: auto;

        @media all and (max-width: 900px) {
          max-width: 360px;
        }
        @media all and (max-width: 600px) {
          max-width: 200px;
        }
      }
    }
    .secondary {
      color: var(--color-secondary);
    }
    .primary {
      color: var(--color-primary);
    }
  }
}
