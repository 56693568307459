.article-page {
  .ArticleHeroSection {
    background: var(--color-background-gray);

    .h1 {
      font-size: 4.8rem;
      line-height: calc(60 / 50);
      font-weight: bold;

      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2.4rem;
      }
    }

    .category {
      font-size: 1.8rem;
      font-weight: 300;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }

    .author {
      .avatar {
        width: 64px;
        border-radius: 64px;
        position: relative;
        z-index: 1;
        margin: auto;
        margin-bottom: 16px;

        img {
          width: 100%;
          position: relative;
          z-index: 2;
          border-radius: 64px;
        }

        &:after {
          z-index: 0;
          content: "";
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          border-radius: 64px;
          background: linear-gradient(180deg, #1285ff 0%, #45dfa8 100%);
          position: absolute;

          transform: translateX(-4px) translateY(3px);
        }
      }
      .name {
        font-size: 1.8rem;
        font-weight: 600;
        padding-bottom: 8px;
        @media all and (max-width: 1600px) {
          font-size: 1.6rem;
        }
        @media all and (max-width: 1200px) {
          font-size: 1.4rem;
        }
      }

      .date {
        font-size: 1.4rem;
        font-weight: 300;
        @media all and (max-width: 1600px) {
          font-size: 1.3rem;
        }
        @media all and (max-width: 1200px) {
          font-size: 1.2rem;
        }
      }
    }

    .secondary {
      color: var(--color-secondary);
    }
    .primary {
      color: var(--color-primary);
    }
  }
}
