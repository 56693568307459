.text-block {
  img {
    width: 100%;
    height: auto;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  h1.subtitle,
  h2.subtitle,
  h3.subtitle,
  h4.subtitle,
  h5.subtitle,
  h6.subtitle,
  &.subtitle,
  .subtitle {
    font-size: 2.2rem;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 2.2rem;
    }
  }

  @for $i from 2 to 9 {
    &.weight-#{$i}00,
    .weight-#{$i}00 {
      font-weight: #{$i}00;
    }
  }

  a,
  ul,
  li,
  p {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-top: 8px;
    margin-bottom: 8px;
    @media all and (max-width: 1200px) {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  .justify-right,
  &.justify-right {
    display: flex;
    justify-content: right;
  }

  .text-center,
  &.text-center {
    text-align: center;
  }

  .text-right,
  &.text-right {
    text-align: right;
  }

  ul {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-inline-start: 24px;

    li {
      line-height: 1.4;
    }
  }

  .red,
  &.red {
    color: var(--color-error);
  }
}
