.BeforeAfterSection {
  background: white;
  .after-irmatiq {
    .RainbowTile {
      transform: scale(1.02);
      &:hover {
        // transform: scale(1.02);
        // &::before {
        //   top: 16px;
        //   left: 12px;
        // }
      }
    }
    h2 {
      font-size: 4rem;
      padding-bottom: 24px;
      @media all and (max-width: 1600px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 2.8rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2.4rem;
      }
    }
    ul {
      font-size: 2.4rem;
      font-weight: 300;
      padding-inline-start: 0px;
      list-style: none;
      @media all and (max-width: 1600px) {
        font-size: 1.8rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }
      li {
        padding-top: 6px;
        padding-bottom: 6px;
        line-height: 1.5;
      }
    }
  }

  .before-irmatiq {
    filter: grayscale(1);
    h2 {
      font-size: 3.4rem;
      padding-bottom: 24px;
      @media all and (max-width: 1600px) {
        font-size: 3rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 2.6rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2rem;
      }
    }
    ul {
      font-size: 2rem;
      font-weight: 300;
      padding-inline-start: 0px;
      list-style: none;

      @media all and (max-width: 1600px) {
        font-size: 1.8rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }
      li {
        padding-top: 4px;
        padding-bottom: 4px;
        line-height: 1.4;
      }
    }
  }

  .RainbowTile {
    transform: translateY(-4px);
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);
    border-radius: 18px;
    transition-duration: 3s;

    &:after,
    &:before {
      border-radius: 16px;
    }
    &::before {
      transition-duration: 3s;
      top: 12px;
      left: 8px;
    }
  }
}
