.TakeFlowSection {
  background: white;
  .section-intro {
    h2 {
      font-size: 4.8rem;
      text-align: center;
      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 3.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2.8rem;
      }
    }

    p {
      text-align: center;
      font-size: 1.8rem;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }
  }
  img {
    width: 100%;
  }
}
