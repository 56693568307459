.InfoSectionPrimary {
  background: var(--color-primary);

  .light {
    color: var(--color-fonts-on-primary);
  }

  h2 {
    font-size: 4.8rem;
    line-height: calc(60 / 50);
    @media all and (max-width: 1600px) {
      font-size: 4rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 3.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.8rem;
    }
  }

  .content {
    .col-info-wrapper {
      margin-left: -24px;
      @media all and (max-width: 1200px) {
        margin-left: 0px;
      }
    }
    p,
    .description {
      font-size: 1.8rem;
      line-height: calc(33px / 18px);
      font-weight: 300;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }
  }
}
