.HeroSection {
  display: flex;
  background: var(--color-background-gray);
  padding-top: 48px;
  padding-bottom: 64px;
  overflow-x: hidden;
  min-height: calc(100vh - 80px);
  @media all and (max-width: 900px) {
    min-height: 0px;
    padding-bottom: 24px;
    padding-top: 24px;
  }

  .hideOnMobile {
    @media all and (max-width: 900px) {
      display: none;
    }
  }
  .showOnMobile {
    @media all and (min-width: 900px) {
      display: none;
    }
  }
  .users-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    @media all and (max-width: 1200px) {
      margin-left: -4px;
    }
    .pre-userlist-label {
      width: 100%;
      font-size: 1.4rem;
      margin: 8px;
      @media all and (max-width: 1200px) {
        margin: 4px;
      }
    }
    @media all and (max-width: 900px) {
      justify-content: start;
    }
    button {
      margin: 8px;
      @media all and (max-width: 1200px) {
        margin: 4px;
        padding: 12px 24px;
      }
      @media all and (max-width: 900px) {
        margin: 2px 2px;
        padding: 8px 12px;
        border-radius: 4px;
      }
    }
  }
  .eu-logos-wrapper {
    img {
      max-width: 296px;
      width: 100%;
    }
  }
  .content {
    .label {
      font-size: 1.4rem;
      color: white;
      background: var(--color-secondary);
      display: inline-block;
      padding: 6px 10px 4px 10px;
      border-radius: 4px;
      line-height: 100%;
      font-weight: bold;
      margin-bottom: 8px;
      @media all and (max-width: 900px) {
        margin-bottom: 4px;
        font-size: 1.2rem;
        text-align: center;
      }
    }
    h1 {
      font-size: 4.8rem;
      line-height: 1.2;
      margin-bottom: 0px;
      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 3.4rem;
      }
    }

    p {
      padding-top: 12px;
      padding-bottom: 32px;
      font-size: 2.2rem;
      font-weight: 300;
      line-height: 1.6;
      margin-bottom: 0px;
      @media all and (max-width: 1600px) {
        font-size: 1.8rem;
        padding-top: 16px;
        padding-bottom: 24px;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
        padding-top: 4px;
        padding-bottom: 16px;
      }
    }
    .cta-buttons {
      @media all and (max-width: 900px) {
        flex-wrap: wrap;
      }
    }
    .or {
      font-size: 1.8rem;
      font-weight: 300;
      color: var(--color-fonts-gray);
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 1.2rem;
      }
    }

    .small-text {
      font-size: 1.4rem;
      font-weight: 300;
      color: var(--color-fonts-gray);
      @media all and (max-width: 1200px) {
        font-size: 1.2rem;
      }
      @media all and (max-width: 900px) {
        padding-top: 8px;
      }
    }
  }

  .hero-img {
    perspective: 75vw;

    @media all and (max-width: 1200px) {
      perspective: initial;
      margin-top: 64px;
    }
    @media all and (max-width: 900px) {
      perspective: initial;
      margin-top: 32px;
    }
    @media all and (max-width: 600px) {
      perspective: initial;
      margin-top: 16px;
    }
    img {
      transform: translateX(200px) rotateY(-20deg);
      box-shadow: 10px 5px 30px rgba(0, 0, 0, 0.1);
      width: 100%;
      margin-bottom: 54px;
      @media all and (max-width: 1900px) {
        transform: translateX(100px) rotateY(-20deg);
      }
      @media all and (max-width: 1600px) {
        transform: translateX(0px) rotateY(-20deg);
        box-shadow: 10px 5px 24px rgba(0, 0, 0, 0.1);
      }
      @media all and (max-width: 1200px) {
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
      }
      @media all and (max-width: 900px) {
        transform: translateX(0px) rotateY(0deg);
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid white;
        margin-bottom: 32px;
      }
      @media all and (max-width: 600px) {
        margin-bottom: 16px;
      }
    }
  }
}
