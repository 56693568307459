.prefooter {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: calc(30 / 14);
  background: var(--color-background);

  @media all and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  .logo {
    height: 58px;
    @media all and (max-width: 900px) {
      height: 42px;
    }
  }

  .eu-logos-wrapper {
    img {
      max-width: 296px;
      width: 100%;
    }
  }

  .subnavbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a {
      display: flex;
      line-height: 1.03;
      margin-top: 7px;
      margin-bottom: 6px;
      white-space: nowrap;
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0%;
        bottom: 1px;
        height: 1.5px;
        background: linear-gradient(270.27deg, #1285ff -17.37%, #1285ff -17.36%, #45dfa8 114.79%);
        transition-duration: 0.2s;
        @media all and (max-width: 1200px) {
          bottom: -1px;
        }
        @media all and (max-width: 600px) {
          bottom: -2px;
        }
      }

      &:hover {
        &:after {
          width: 70%;
        }
      }
    }

    // .active {
    //   &:after {
    //     width: 100%;
    //   }
    // }
  }
  .subnav-col {
    .subnavbar {
      align-items: flex-end;
    }
  }
  .social-media {
    a {
      text-decoration: underline;
    }
  }
}
