.layout {
  position: relative;

  .main {
    padding-top: 0px;
    transition-duration: 0.3s;

    &.navbar-stuck {
      transition-duration: 0s;
    }

    .page-wrapper {
    }
  }
}
