.TextInput {
  padding-bottom: 16px;
  position: relative;

  .validation-messages {
    transition-duration: 0.15s;
    display: inline-block;
    transform: translateY(20px);
    color: var(--color-error);
    opacity: 0;

    &.active {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  label {
    display: block;
    padding-top: 3px;
    transform: translateY(25px);
    font-size: 2.4rem;
    font-weight: 300;
    transition-duration: 0.3s;
    padding-bottom: 8px;
    user-select: none;
    @media all and (max-width: 1600px) {
      font-size: 2rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.6rem;
    }
  }
  input {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 0px 0px 16px 0px;
    border: 0px;
    border-bottom: 2px solid var(--color-fonts);
    border-style: solid;
    background: transparent;
    color: var(--color-fonts);
    font-size: 2.4rem;
    font-weight: 300;
    border-radius: 0px;

    @media all and (max-width: 1600px) {
      font-size: 2rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.6rem;
    }
  }

  &.isDirty,
  &.isFocus {
    label {
      transform: translateY(0px) scale(0.6);
      transform-origin: left;
      // font-weight: 400;
      opacity: 0.85;
    }
  }

  &.isFocus {
    input {
      border-color: var(--color-fonts-on-primary);
      color: var(--color-fonts-on-primary);
    }
  }
}
