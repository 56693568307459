.CloseToSection {
  background: var(--color-background-gray);
  @media all and (max-width: 900px) {
    .reverse-on-mobile {
      flex-direction: column-reverse;
    }
  }
  .content-col {
    margin-top: 32px;
    min-height: 540px;
    @media all and (max-width: 1600px) {
      margin-top: 16px;
      margin-bottom: 8px;
      min-height: 480px;
    }
    @media all and (max-width: 1200px) {
      margin-top: 16px;
      margin-bottom: 8px;
      min-height: 400px;
    }
    @media all and (max-width: 900px) {
      min-height: initial;
    }
    h3 {
      font-size: 3.4rem;
      line-height: calc(60 / 50);
      // @media all and (max-width: 1600px) {
      //   font-size: 4rem;
      // }
      // @media all and (max-width: 1200px) {
      //   font-size: 3.4rem;
      // }
      @media all and (max-width: 900px) {
        font-size: 2.8rem;
      }
      @media all and (max-width: 600px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.8rem;
      font-weight: 300;
      line-height: calc(33 / 18);
      max-width: 512px;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }
  }
  .bg-disks-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    @media all and (max-width: 900px) {
      width: 100%;
      padding-bottom: 64px !important;
    }
    .bg-disks {
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 111%;
      transform: translateX(-2%) translateY(-3%);
      position: absolute;
      z-index: 0;
    }
    img {
      position: relative;
      z-index: 1;
      width: 100%;
      border-radius: 10px;
      box-shadow: 20px 20px 0px rgba(0, 0, 0, 0.06);
      margin-top: 96px;
      @media all and (max-width: 1200px) {
        box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.06);
      }
      @media all and (max-width: 900px) {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
}
