.RainbowTile {
  display: block;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 48px;
  background: var(--color-background);
  position: relative;
  @media all and (max-width: 1600px) {
    padding: 32px;
  }
  @media all and (max-width: 1200px) {
    padding: 32px;
  }
  @media all and (max-width: 900px) {
    padding: 24px;
  }
  @media all and (max-width: 600px) {
    padding: 20px;
  }
  p {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.5;

    @media all and (max-width: 1600px) {
      font-size: 2rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.8rem;
    }
    @media all and (max-width: 900px) {
      font-size: 1.6rem;
      line-height: 1.25;
    }
  }

  transition-duration: 0.2s;
  z-index: 222;
  position: relative;
  background: var(--color-background);
  border: 1px solid var(--color-border-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.isSad,
  &.isRainbow {
    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
      background: var(--color-background);
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 2;
      transition-duration: 0.3s;
      border-radius: 8px;
    }
    &::before {
      background: linear-gradient(90deg, #45dfa8 0%, #1285ff 100%);
      top: 3px;
      z-index: 1;
    }
    * {
      z-index: 3;
      position: relative;
    }
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.05);

      &::before {
        background: linear-gradient(30deg, #45dfa8 0%, #1285ff 100%);
        top: 8px;
        left: 6px;
        z-index: 1;
      }

      .options-wrapper {
        text-align: left;
      }
    }
  }
  &.isSad {
    opacity: 0.5;
    &::before {
      background: linear-gradient(180deg, hsl(211, 0%, 68%) 0%, hsl(211, 0%, 54%) 100%);
    }

    &:hover {
      &::before {
        background: linear-gradient(180deg, hsl(211, 0%, 68%) 0%, hsl(211, 0%, 54%) 100%);
      }
    }
  }
}

.IrmatiqUsers {
  .RainbowTile {
    h2 {
      font-size: 3.6rem;
      font-weight: 600;
      line-height: calc(33 / 18);
      @media all and (max-width: 1600px) {
        font-size: 3.2rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 2.8rem;
      }
      @media all and (max-width: 900px) {
        font-size: 2rem;
        line-height: 1.25;
      }
    }
    h3 {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.5;
      @media all and (max-width: 1900px) {
        font-size: 2rem;
      }
      @media all and (max-width: 1600px) {
        font-size: 2rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.8rem;
      }
      @media all and (max-width: 900px) {
        font-size: 1.8rem;
        line-height: 1.25;
      }
    }
    border-radius: 24px !important;
  }
  .isRainbow {
    &::before,
    &::after {
      border-radius: 22px !important;
    }

    &::before {
      background: linear-gradient(180deg, #2dcdcc 0%, #006dce 100%);
    }

    &:hover {
      &::before {
        background: linear-gradient(180deg, #2dcdcc 0%, #006dce 100%);
      }
    }
  }
}
