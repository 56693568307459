.Tile {
  display: block;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 64px 42px 64px 42px;
  background: var(--color-background);
  position: relative;
  @media all and (max-width: 1600px) {
    padding: 48px 32px 48px 32px;
  }
  @media all and (max-width: 1200px) {
    padding: 32px 24px 32px 24px;
  }
  @media all and (max-width: 900px) {
    padding: 24px 16px 24px 16px;
  }
  @media all and (max-width: 600px) {
    padding: 20px 12px 20px 12px;
  }

  &.Tile--no-footer {
    padding-bottom: 0px;
  }

  .icon {
    height: 128px;
    background-position: left center;
    background-repeat: no-repeat;
    @media all and (max-width: 900px) {
      height: 72px;
      background-size: contain;
    }
    @media all and (max-width: 600px) {
      height: 64px;
    }
  }
  h3 {
    padding-top: 72px;
    padding-bottom: 12px;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: calc(33 / 24);
    @media all and (max-width: 1600px) {
      font-size: 2rem;
      padding-top: 48px;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.6rem;
      padding-top: 32px;
    }
    @media all and (max-width: 600px) {
      font-size: 1.4rem;
      padding-top: 24px;
    }
  }
  p {
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 96px;
    line-height: calc(33 / 18);
    @media all and (max-width: 1600px) {
      font-size: 1.6rem;
      margin-bottom: 64px;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.4rem;
      margin-bottom: 48px;
    }
    @media all and (max-width: 900px) {
      font-size: 1.3rem;
      line-height: 1.25;
      margin-bottom: 32px;
    }
  }
  .tile-footer {
    position: absolute;
    bottom: 48px;
    text-align: left;
    @media all and (max-width: 1200px) {
      bottom: 32px;
    }
    @media all and (max-width: 900px) {
      bottom: 20px;
    }
    a {
      color: var(--color-secondary);
      text-decoration: underline;
      font-size: 1.8rem;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
      @media all and (max-width: 900px) {
        font-size: 1.3rem;
      }
    }
  }
}
