.NumberTile {
  display: block;
  text-align: center;

  .gradient-circle {
    margin: auto;
    width: 128px;
    height: 128px;
    border-radius: 128px;
    justify-content: center;
    align-items: center;
    display: flex;
    background: linear-gradient(122.54deg, #1285ff -6.84%, #45dfa8 136.96%);
    font-size: 4.8rem;
    font-weight: bold;
    color: var(--color-fonts-on-primary);
    padding-top: 4px;

    @media all and (max-width: 1600px) {
      font-size: 4rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 3.4rem;
      width: 96px;
      height: 96px;
      border-radius: 96px;
    }
    @media all and (max-width: 900px) {
      font-size: 2.8rem;
    }
  }

  .content {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: calc(33 / 18);
    @media all and (max-width: 1600px) {
      font-size: 1.6rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.4rem;
    }
  }
}
