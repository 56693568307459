.navbar {
  position: sticky;
  top: -0.1px;
  background: var(--color-background-gray);
  border-bottom: 1px solid var(--color-background-gray);
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0);
  transition-duration: 0s;
  z-index: 9999;
  @media all and (max-width: 1900px) {
    height: 66px;
  }
  @media all and (max-width: 900px) {
    height: 66px;
  }
  .container {
    z-index: 9998;
    position: relative;
  }

  &::before {
    display: block;
    position: absolute;
    width: 100%;
    height: 0px;
    background: var(--color-background-gray);
    content: "";
    top: 0px;
    transition-duration: 0s;
  }

  img.logo {
    z-index: 9998;
    transition-duration: 0.3s;
    height: 54px;
    @media all and (max-width: 1900px) {
      height: 48px;
    }
    @media all and (max-width: 1600px) {
      height: 42px;
    }
    @media all and (max-width: 900px) {
      height: 38px;
    }
  }
  .subnavbar a,
  .lang-switch.nav-item button,
  .nav-item {
    font-size: 1.6rem;
    font-weight: 400;
    white-space: nowrap;
    @media all and (max-width: 1600px) {
      font-size: 1.5rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.4rem;
    }
  }

  .logos {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .eu-logo-img {
      width: 230px;
      margin-left: 16px;
      border-left: 1px solid #eee;
      padding-left: 16px;
      @media all and (max-width: 1600px) {
        width: 180px;
        margin-left: 12px;
        padding-left: 12px;
      }
      @media all and (max-width: 1200px) {
        width: 160px;
        margin-left: 8px;
        padding-left: 8px;
      }
      @media all and (max-width: 900px) {
        width: 160px;
        border-left: none;
        padding-left: initial;
        margin-left: initial;
      }
    }
  }
  .subnavbar a,
  .nav-item {
    margin-left: 28px;
    @media all and (max-width: 1600px) {
      margin-left: 24px;
    }
    @media all and (max-width: 1440px) {
      margin-left: 16px;
    }
    @media all and (max-width: 1200px) {
      margin-left: 16px;
    }
  }

  .subnavbar {
    display: flex;
    a {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0%;
        bottom: 0px;
        height: 2px;
        background: linear-gradient(270.27deg, #1285ff -17.37%, #1285ff -17.36%, #45dfa8 114.79%);
        transition-duration: 0.2s;
        @media all and (max-width: 1200px) {
          bottom: -1px;
        }
      }

      &:hover {
        &:after {
          width: 60%;
        }
      }
    }

    .active {
      &:after {
        width: 100% !important;
      }
    }
  }

  // &.stuck {
  //   background: var(--color-background);
  //   border-bottom: 1px solid var(--color-background-gray);
  //   box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
  //   transition-duration: 0.3s;
  // }

  .mobile-nav {
    display: none;
    @media all and (max-width: 900px) {
      display: flex;

      flex-direction: column;
      position: absolute;
      top: -128vh;
      height: calc(100vh - 70px);
      background: var(--color-background-gray);
      width: 100%;
      align-items: center;
      padding-top: 16px;
      transition-duration: 0.3s;
      z-index: 9997;
      opacity: 0;
      transition-delay: 0.3s;

      .subnavbar {
        flex-direction: column;
        align-items: center;
        a {
          &:after {
            height: 1px;
          }
        }
      }
      .subnavbar,
      .nav-item {
        margin-top: 0px;
        opacity: 0;
        transition-delay: 0s;
        transition-duration: 0.3s;
      }
      &.--open {
        opacity: 1;
        top: 66px;
        transition-delay: 0s;

        .subnavbar,
        .nav-item {
          opacity: 1;
          transition-delay: 0.3s;
        }

        .subnavbar {
          margin-top: 32px;
        }
      }

      .subnavbar a,
      .nav-item {
        margin-left: 0px;
        margin-bottom: 32px;
        font-size: 1.4rem;
      }
    }
  }
}
.navbar-stuck {
  transition-duration: 0.3s;

  .navbar {
    // background: var(--color-background-gray);
    //var(--color-background-gray);
    border-bottom: 1px solid var(--color-border-gray);
    box-shadow: 0px 0px 256px rgba(0, 0, 0, 0.05);
    transition-duration: 0.3s;
  }
}
