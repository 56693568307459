.router-transition {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: -100vh;
  left: 0vw;
  z-index: 800;

  &.active {
    top: 0vh;
  }

  .router-transition__opacity {
    height: 100%;
    width: 100%;
    transition-duration: 0.2s;
    background: var(--color-background-00a);

    &.active {
      transition-duration: 0s;
      background: var(--color-background-gray);
    }
  }
}
