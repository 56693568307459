.TextSection {
  background: var(--color-background-gray);

  h2 {
    font-size: 4.8rem;
    line-height: calc(60 / 50);
    @media all and (max-width: 1600px) {
      font-size: 4rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 3.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.8rem;
    }
  }

  h3 {
    font-size: 3rem;
    line-height: calc(60 / 50);
    padding-top: 48px;

    @media all and (max-width: 1600px) {
      font-size: 2.4rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 2rem;
    }
    @media all and (max-width: 900px) {
      font-size: 1.6rem;
    }
  }

  .content {
    ul {
      margin-top: 48px;
      margin-bottom: 48px;
    }
    p {
      margin-bottom: 8px;
      margin-top: 8px;
    }
    li,
    p {
      font-size: 1.8rem;
      line-height: calc(33px / 18px);
      font-weight: 300;
      @media all and (max-width: 1600px) {
        font-size: 1.6rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
      }
    }
  }
}
