.ArticleTile {
  .preview {
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-bottom: 32px;
    @media all and (max-width: 1200px) {
      margin-bottom: 24px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .content {
    .title {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: calc(40 / 25);

      @media all and (max-width: 1600px) {
        font-size: 2rem;
      }
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }
    }
  }
  .redirect-ala-link,
  .content .description {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: calc(33 / 18);
    @media all and (max-width: 1600px) {
      font-size: 1.6rem;
    }
    @media all and (max-width: 1200px) {
      font-size: 1.4rem;
    }
  }

  .redirect-ala-link {
    color: var(--color-secondary);
    background: color;
    text-decoration: underline;
  }
}
