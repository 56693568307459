.lang-switch {
  &.lang-btns {
    button {
      cursor: pointer;
      background: transparent;
      border: none;
      opacity: 0.25;

      &.active {
        opacity: 1;
      }
    }
  }

  .break {
    color: var(--color-secondary);
  }

  .hiddenBtn {
    position: absolute;
    top: -100px;
    opacity: 0.01;
  }
}
